import React from 'react';
import PropTypes from 'prop-types';
import { iconCircle, iconNoBackground, iconSquare } from './icons';

const ICON_TYPE_SQUARE = 1;
const ICON_TYPE_CIRCLE = 2;
const ICON_TYPE_NO_BACKGROUND = 3;

const iconByType = {
  [ICON_TYPE_SQUARE]: iconSquare,
  [ICON_TYPE_CIRCLE]: iconCircle,
  [ICON_TYPE_NO_BACKGROUND]: iconNoBackground,
};

const RssIcon = ({ iconType }) => {
  const Component = iconByType[iconType] || iconSquare;
  return <Component />;
};

RssIcon.propTypes = {
  iconType: PropTypes.number,
};

RssIcon.defaultProps = {
  iconType: ICON_TYPE_SQUARE,
};

export default RssIcon;
